






















































































































































































































































import Vue from "vue";
import DeleteForm from "./DeleteForm.vue";
import Player from "./Player.vue";
import formatter, { mapNameTrim } from "../formatter";
import parseISO from "date-fns/parseISO/index";
import { format, isDate, isValid } from "date-fns";
import { getURL } from "../axios";
import{ debugLog} from '../util/debug'

type LocalData = {
  fileURL: string | null;
  show: boolean;
  publicPath: string;
};
export default Vue.extend({
  props: {
    replay: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    DeleteForm,
    Player,
  },
  data(): LocalData {
    return {
      fileURL: null,
      show: false,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    game(): any {
      return this.replay.game;
    },
    player(): any[] {
      if (!this.replay?.player) return [];
      return this.replay.player.map((p: any, i: number) => {
        return { ...p, key: `${new Date().getTime()}-${i}` };
      });
    },
    team1(): any[] {
      if (this.game.GameType === "0") {
        return this.player.filter((p: any) => {
          return p.PlayerAlliance === 0;
        });
      }
      return this.replay.player.filter((_: any, i: number) => {
        return i % 2 === 0;
      });
    },
    team2(): any[] {
      if (this.game.GameType === "0") {
        return this.player.filter((p: any) => {
          return p.PlayerAlliance === 1;
        });
      }
      return this.replay.player.filter((_: any, i: number) => {
        return i % 2 === 1;
      });
    },
    detailUrl(): string {
      return `https://wargamerts-tool.com?id=${this.replay.id}`;
    },
    twUrl(): string {
      return `https://twitter.com/intent/tweet?text=Check%20out%20the%20replay.%20%7C%20Wargame:RD%20replay%20uploader%28α%29&url=https%3A%2F%2Fwargamerts-tool.com%3Fid=${this.replay.id}&hashtags=wargameRTS`;
    },
    rdUrl(): string {
      return `https://reddit.com/submit?url=https%3A%2F%2Fwargamerts-tool.com%3Fid%3D${this.replay.id}&title=Check%20out%20the%20replay.%20%7C%20Wargame:RD%20replay%20uploader%28α%29`;
    },
  },
  mounted(): void {
    this.$nextTick(() => {
      this.$emit("loaded");
    });
  },
  methods: {
    createdDate(val: string): string {
      const c: Date = parseISO(val);
      if (!isDate(c) || !isValid(c)) return "n/a";
      return format(c, "yyyy/MM/dd HH:mm:dd");
    },
    gameMode(val: string): string {
      return formatter.gameMode(val.toString());
    },
    victoryCondition(val: string): string {
      return formatter.victoryCondition(val);
    },
    gameType(val: string): string {
      return formatter.gameType(val.toString());
    },
    incomeRate(val: string): string {
      return formatter.incomeRate(val.toString());
    },
    mapImage(val: string): string {
      return mapNameTrim(val);
    },
    gameMap(val: string): string {
      return formatter
        .map(val.toString())
        .replace("#land_sea ", "")
        .replace("#sea ", "");
    },
    timeLimit(val: number): string {
      return formatter.timeLimit(val);
    },
    mapTerrain(val: string): number {
      const name: string = formatter.map(val.toString());
      if (!name) return 0;
      // 海あり
      if (name.indexOf("#land_sea") > -1) return 1;
      // 海のみ
      if (name.indexOf("#sea") > -1) return 2;
      return 0;
    },
    download(id: string): void {
      this.fileURL = null;
      this.$gtag.event("download");
      getURL(id)
        .then((res: any) => {
          debugLog(res);
          const url: string = res.url;
          return url;
        })
        .then((url: string) => {
          this.fileURL = url;
        })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs[`dl-${this.replay.id}`] as any).click();
        })
        .then(() => {
          this.fileURL = null;
        })
        .catch((e) => {
          debugLog(e);
          this.$emit("error");
          this.fileURL = null;
        });
    },
    open(): void {
      this.show = true;
    },
    jump(url: string): void {
      window.open(url);
    },
    copy(str: string): void {
      if (navigator.clipboard) {
        this.$gtag.event("copy_url");
        try {
          navigator.clipboard.writeText(str);
          this.$emit("copy", "url copied.");
        } catch (error) {
          debugLog(error);
          this.$emit("error");
        }
      }
    },
  },
});
