

















































































import UploadForm from "../components/UploadForm.vue";
import ReplayCard from "../components/ReplayCard.vue";
import { detail, list } from "../axios";
import{ debugLog} from '../util/debug'

import Vue from "vue";
import { parseISO } from "date-fns";

type UploadResult = {
  id: string;
  pin: string;
};

type LocalData = {
  snackbar: boolean;
  message: string;
  isEnd: boolean;
  loadDisabled: boolean;
  overlay: boolean;
  replays: any[];
};

export default Vue.extend({
  name: "Home",
  components: {
    UploadForm,
    ReplayCard,
  },
  data(): LocalData {
    return {
      snackbar: false,
      message: "upload",
      isEnd: false,
      loadDisabled: true,
      overlay: false,
      replays: [],
    };
  },
  computed: {
    oldestDate(): Date | undefined {
      if (!this.replays) return undefined;
      const l: number = this.replays.length;
      return parseISO(this.replays[l - 1]?.createdDate);
    },
  },
  created(): void {
    const id: string | undefined = this.$route.query?.id?.toString();
    if (id) {
      this.$router.push({ name: "detail", query: { id } });
      return;
    }
    this.init();
    this.getReplaylist(10);
  },
  methods: {
    init(): void {
      this.replays = [];
      this.message = "upload";
      this.snackbar = false;
    },
    uploaded(payload: UploadResult): void {
      const id: string = payload.id;
      const pin: string = payload.pin;
      debugLog(id, pin);
      if (id) {
        this.getReplay(id);
      }
      const snackberMsgOption: string = pin ? `delete key is "${pin}".` : "";
      this.showSnackbar(`upload successful.${snackberMsgOption}`);
    },
    remove(id: string): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const i: number = this.replays.findIndex((r: any) => r.id === id);
      if (i < 0) return;
      this.replays.splice(i, 1);
    },
    getReplay(id: string) {
      detail(id.toString())
        .then((replay) => {
          debugLog(replay);
          this.replays.unshift(replay);
        })
        .catch((e) => {
          debugLog(e);
          this.$emit("error");
        });
    },
    async getReplaylist(limit = 10, start: Date | undefined = undefined) {
      this.loadDisabled = true;
      await list(limit, start)
        .then((replayList: any[]) => {
          if (replayList.length === 0) {
            this.isEnd = true;
            return;
          }
          replayList.forEach((r: any) => {
            this.replays.push(r);
          });
          this.loadDisabled = false;
        })
        .catch((e: any) => {
          debugLog(e);
        });
    },

    showSnackbar(str: string): void {
      this.message = str || "エラー";
      this.snackbar = true;
    },
  },
});
