














































import Vue from "vue";
import { decodeDeck, DeckInfo } from "../deckDecoder";
export default Vue.extend({
  props: {
    deck: {
      type: String,
      default: (): string => {
        return "";
      },
    },
    playerName: {
      type: String,
      default: (): string => {
        return "n/a";
      },
    },
    versionStr:{
      type:String,
      default:()=>''
    }
  },
  computed:{
    decoded():DeckInfo | null{
      return this.decode(this.deck,this.version)
    },
    version():number{
      return parseInt(this.versionStr)
    }
  },
  methods: {
    copy(str: string): void {
      if (navigator.clipboard) {
        this.$gtag.event("copy_deck");
        try {
          navigator.clipboard.writeText(str);
          this.$emit("copy");
        } catch (error) {
          this.$emit("error");
        }
      }
    },
    decode(deck: string,version:number): DeckInfo | null {
      return decodeDeck(deck,version);
    },
    era(cat: string): string {
      const eraMap: { [T: string]: string } = {
        A: "All",
        B: "Before 1985",
        C: "Before 1980",
      };
      return eraMap[cat] || cat;
    },
  },
});
