

























import Vue from "vue";
import AppBar from "./components/AppBar.vue";
import Drawer from "./components/Drawer.vue";

export default Vue.extend({
  name: "App",
  components: {
    AppBar,
    Drawer,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  methods: {
    goToTop(): void {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
});
