














import Vue from "vue";
export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      group: false,
    };
  },
  computed: {
    drawer: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean) {
        this.$emit("input", val);
      },
    },
  },
});
